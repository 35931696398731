





























































































import Vue from 'vue';
import projectForm from './project-form.vue';
import * as api from '@/api/project';
import moment from 'moment';
interface resData {
  [key: string]: any;
}
export default Vue.extend({
  name: 'project',
  components: {
    projectForm,
  },
  data() {
    return {
      action: 'add',
      detailsId: '',
      showStatus: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      },
      keyword: '',
      tableData: [],
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    details(sequenceNbr: string) {
      this.$store.commit('SET_PROJECTSEQUENCENBR', sequenceNbr);
      this.$router.push({
        path: '/project/detail',
        query: { sequenceNbr, spaceId: '1486253838464360450' },
      });
    },
    heandDate(val: Date) {
      return moment(val).format('YYYY-MM-DD');
    },
    refresh() {
      this.page.currentPage = 1;
      this.getList();
    },
    showForm(type = 'add', sequenceNbr = '') {
      this.action = type;
      this.detailsId = sequenceNbr;
      this.showStatus = true;
    },
    change(page: number) {
      this.page.currentPage = page;
      this.getList();
    },
    showSizeChange(current: number, size: number) {
      this.page.currentPage = 1;
      this.page.pageSize = size;
      this.getList();
    },
    getList() {
      api
        .coccMtdsResource_1_project_constructs({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          constructName: this.keyword,
        })
        .then((res: resData) => {
          let { result } = res;
          this.tableData = result.list;
          this.page.total = result.total;
        });
    },
  },
});
